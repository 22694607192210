.internas{

	.pag-header{
		background-color: $vermelho;
		padding: 10px 0;
		font-size: 12px;
		font-family: $mts-bold;
		line-height: 1;
		text-transform: uppercase;
		color: #fff;

		span + span{
			margin-left: 5px;
		}

		@include media-breakpoint-up(md) {
			text-align: right;
		}
		@include media-breakpoint-down(sm) {
			text-align: center;
		}
	}// .pag-header

	.pag-body{
		padding-top: 60px;
		padding-bottom: 60px;

		.titulo-pag{
			color: #000;
			font-size: 22px;
			font-family: $mts-black;
			text-transform: uppercase;
			line-height: 24px;
			border-bottom: 10px solid #000;
			padding-bottom: 10px;
			margin-bottom: 30px;
		}
		.subtitulo-pag{
			font-size: 18px;
			line-height: 20px;
			font-family: $mts-bold;
			text-transform: uppercase;
			margin-bottom: 10px;
			color: #000;
		}
	}//.pag-body

}// .internas

.pag-login{
	.txt-cadastro{
		font-size: 16px;
		line-height: 18px;
		color: #555;
	}
	.btn-padrao{
		margin: 20px auto 0 auto;
	}
	.botoes{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		.btn-padrao{
			margin: 5px;
		}
	}
	@include media-breakpoint-only(xl) {
		.txt-cadastro{
			padding-top: 18px;
		}
	}
	@include media-breakpoint-between(md, lg) {
		.txt-cadastro{
			padding-top: 20px;
		}
	}
	@include media-breakpoint-down(sm) {
		.col-md-6 + .col-md-6{
			margin-top: 30px;
		}
	}
}// .pag-login

.pag-recuperar{
	.btn-padrao{
		margin: 30px auto 0 auto;
	}
}// .pag-recuperar

.pag-cadastro{
	.bloco-inicio{
		margin-bottom: 20px;
		.subtitulo-pag{
			margin-bottom: 0;
		}
		.campos{
			color: $vermelho;
			font-size: 12px;
			font-family: $mts-bold;
			font-style: italic;
		}
	}
	.bloco-endereco{
		margin: 20px 0;
		p{
			font-size: 14px;
			color: #000;
		}
	}
	.label-select{
		display: flex;
		align-items: center;
		margin-top: 10px;
		font-size: 14px;
		color: #555;
		font-family: $mts-bold;
		text-transform: uppercase;

		label + label{
			margin-left: 10px;
		}
		label{
			margin-bottom: 0;
			position: relative;
			display: flex;
			align-items: center;
			cursor: pointer;

			&:before{
				content: '';
				position: relative;
				display: block;
				width: 18px;
				height: 18px;
				border-radius: 50%;
				left: 0;
				top: 0;
				background-color: #ddd;
				margin: auto 10px auto 0;
				transition: all 0.2s linear;
			}
		}
	}
	#formPF,
	#formPJ{
		display: none;
		margin-top: 30px;
	}
	.input-type-pf:checked ~ .label-select label[class="pessoa-fisica"] {
		&:before{
			background-color: $vermelho;
			border-color: $vermelho;
		}
	}
	.input-type-pj:checked ~ .label-select label[class="pessoa-juridica"] {
		&:before{
			background-color: $vermelho;
			border-color: $vermelho;
		}
	}
	.input-type-pf:checked ~ #formPF{
		display: block;
	}
	.input-type-pj:checked ~ #formPJ{
		display: block;
	}
	.receber-emails{
		margin-top: 20px;
		margin-bottom: 30px;
	}
	.btn-padrao{
		margin: 30px 0 0 auto;
	}

	@include media-breakpoint-up(sm) {
		.col-sm-6:nth-child(odd) {
			padding-right: 5px;
		}
		.col-sm-6:nth-child(even) {
			padding-left: 5px;
		}
	}
	@include media-breakpoint-only(xs) {
		.btn-padrao{
			margin-right: auto;
		}
	}

}// .pag-cadastro

.pag-carrinho{
	.table-listagem{
		border-bottom: 10px solid #ddd;
		padding-bottom: 30px;
	}
	.btn-pag{
		background-color: #000;
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 100%;
		width: 200px;
		color: #fff;
		border-radius: 5px;
		padding: 5px;
		height: 42px;
		border: none;
		font-weight: 700;
		font-size: 12px;
		text-align: center;
	}
	.finalizar-compra{
		padding-top: 30px;
		margin-bottom: 20px;

		.simular-frete{
			padding: 15px 30px;
			border: 1px solid #ddd;

			.texto{
				font-size: 13px;
				color: #555;
				position: relative;
				padding: 5px 0 5px 30px;
				span{
					color: #555;
					font-weight: bold;
				}

				&:before{
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 3px;
					height: 100%;
					background-color: $vermelho;
				}
			}

			.forms{
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				margin-top: 10px;

				.form-grupo{
					flex-grow: 1;
					
					.form-control{
						color: #000;
						font-size: 14px;
						height: 44px;
						background-color: #fff;
						border: 1px solid #ccc;

						&::placeholder{
							color: #000;
						}

						&:focus{
							box-shadow: none;
						}
					}
				}
				.btn-pag{
					border: none;
					margin-left: 10px;
					width: 120px;
					outline: none;
				}
			}
			a{
				font-style: italic;
				color: #555;
				font-size: 11px;
			}
			.texto-final{
				font-size: 12px;
				font-style: italic;
				color: #555;
			}
		}
	}
	//finalizar-compra

	.table-finalizar{
		.content{
			background-color: #000;
			color: #fff;
			font-size: 14px;
			padding: 10px 20px;
			margin-bottom: 10px;
			line-height: 1.4;

			.item{
				display: flex;
				align-items: center;
				justify-content: space-between;

				.valor{
					text-align: right;
				}
			}
		}
		.footer{
			background-color: #ededed;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 20px;

			.texto{
				font-size: 14px;
				color: #555;
			}
			.total{
				font-size: 20px;
				color: $vermelho;
				font-family: $mts-bold;
			}
		}
	}

	.cupons{
		.segura-cupons{
			display: flex;
			align-items: center;

			.form-vale{
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-grow: 1;

				@include media-breakpoint-down(sm) {
					flex-direction: column;
					margin-bottom: 10px;
				}
				
				.msg-vale{
					display: flex;
					align-items: center;
					line-height: 1;
					font-size: 12px;
					color: #555;
					font-weight: bold;

					i.far{
						font-size: 22px;
						line-height: 1;
						margin-right: 5px;
						color: #aaa;
					}
				}
				.form-grupo{
					flex-grow: 1;

					.form-control{
						color: #000;
						font-size: 12px;
						height: 44px;
						background-color: #eee;
						border: none;
						border-radius: 4px;

						&::placeholder{
							color: #000;
						}

						&:focus{
							box-shadow: none;
						}
					}
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.cupons{
			display: flex;
			.segura-cupons{
				flex-grow: 1;
			}
			.btn-padrao{
				margin-left: 20px;
			}
			.btn-padrao{
				width: 150px;
				outline: none;
				text-align: center;
			}
		}
	}
	@include media-breakpoint-up(md) {
		.finalizar-compra{
			.simular-frete{
				.texto{
					margin-left: -30px;
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		.table-finalizar{
			margin-top: 30px;
		}
	}
	@include media-breakpoint-only(md) {
		.cupons{
			.segura-cupons{
				.btn-padrao{
					margin-left: 10px;
					margin-top: 0;
					width: 150px;
				}
			}
			.btn-padrao{
				margin-top: 30px;
				margin-left: auto;
				width: 200px;
			}
		}
	}
	@include media-breakpoint-up(sm) {
		.cupons{
			.segura-cupons{
				.form-vale{
					.form-grupo{
						margin-left: 10px;
					}
				}
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.finalizar-compra{
			.simular-frete{
				.texto{
					padding-left: 10px;
				}
			}
		}
		.cupons{
			.segura-cupons{
				flex-direction: column;

				.form-vale{
					width: 100%;
					.form-grupo{
						width: 100%;
						margin-top: 10px;
					}
				}
				.btn-padrao{
					margin-top: 0;
				}
			}
			a.btn-padrao{
				margin-left: auto;
				margin-right: auto;
				margin-top: 15px;
			}
		}
	}
	@include media-breakpoint-only(xs) {
		.finalizar-compra{
			.simular-frete{
				.forms{
					.btn-pag{
						width: 100%;
						margin: 10px 0 0 0;
					}
				}
			}
		}
		.cupons{
			.segura-cupons{
				.form-vale{
					.form-grupo{
						width: 100%;
						margin-top: 10px;
					}
				}
			}
			
		}
	}
}// .pag-carrinho

.pag-finalizar{
	.opcao + .opcao{
		border-top: 8px solid #ddd;
	}
	.opcao{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 30px;
		padding-top: 30px;

		.dados{
			.texto{
				font-size: 13px;
				color: #555;

				&.primeiro{
					font-size: 16px;
					font-family: $mts-bold;
				}
				&.ultimo{
					margin-top: 30px;
				}
				&.dois{
					line-height: 1;
				}
			}
		}
		// dados
		.botao{
			.btn-finaliza-compra{
				font-weight:bold;
				box-shadow:0 2px #aeb3aa;
				background-color: #65b616;
				line-height:1;
				padding-left: 50px;
				width: 210px;
				text-align: left;
				position: relative;
				color:#FFF;
				font-size: 11px;
				text-transform: uppercase;		

				&:before,
				&:after{
					content:'';
					display: block;
					position: absolute;
				}
				&:before{
					width:20px;
					height: 10px;
					background: currentcolor;
					left:0.75rem;
					top:calc(50% - 5px);
				}
				&:after{
					border:10px solid transparent;
					border-left-color:#FFF;
					left: calc(0.75rem + 20px);
					top: calc(50% - 10px);
				}
				span{
					display: block;
					padding-left: 0.75rem;
					padding-bottom:0.35rem;
					padding-top:0.35rem;
					white-space:normal;
					&:before{
						content:'';
						height:100%;
						border-left: 1px solid #65b616;
						position: absolute;
						top:0;
						left: 50px;
					}
				}
			}
		}
		// botao
	}
	// opcao

	.table-finalizacao{
		.table-header{
			background-color: #000;
			color: #fff;
			font-size: 14px;
			text-align: center;
			padding: 10px;
			font-family: $mts-bold;
		}
		.content{
			color: #555;
			padding: 10px;
			background-color: #eee;

			.header{
				border: 1px solid #D6D6D6;
				font-size: 16px;
				padding: 5px 5px 5px 15px;
				color: #555;

				&.items,
				&.endereco{
					color: #000;
					font-family: $mts-bold;
				}

				&.total{
					display: flex;
					background-color: transparent;
					justify-content: space-between;
					align-items: center;
					border: none;

					div:last-child{
						color: #000;
						font-family: $mts-bold;
					}
				}
				&.frete{
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: 14px;
				}
			}

			.body{
				padding: 15px;
				line-height: 1;
				font-size: 13px;
				color: #555;
			}

			.sub-header{
				margin-top: 2px;
				line-height: 1;
				font-size: 13px;
				background-color: #ededed;
				padding: 5px;
				display: flex;
				align-items: center;
				color: #555;

				.nome{
					padding-left: 10px;
				}
				.qtd,
				.valor{
					text-align: center;
				}
			}

			.item + .item{
				border-top: 1px solid #ededed;
			}
			.item{
				display: flex;
				align-items: center;
				line-height: 1;
				font-size: 12px;
				padding: 10px 0;
				.produto{
					padding-left: 10px;
				}
				.qtd,
				.valor{
					text-align: center;
				}
			}
		}
		// .content
	}
	// table-finalizacao

	@include media-breakpoint-up(md) {
		.opcao{
			align-items: flex-end;
		}
		.table-finalizacao{
			.content{
				.sub-header{
					.qtd{
						width: 50px;
					}
					.valor{
						width: 80px;
					}
					.nome{
						width: 200px;
						max-width: 100%;
					}
				}
				.item{
					.produto{
						width: 200px;
						max-width: 100%;
						overflow: hidden;
						max-height: 100%;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						height: 13px;
						line-clamp: 1;
						-webkit-line-clamp: 1;
					}
					.qtd{
						width: 60px;
					}
					.valor{
						width: 80px;
					}
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		.opcao{
			flex-direction: column;
			text-align: center;
			align-items: center;

			.botao{
				margin-top: 10px;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.title-1{
			text-align: center;
			margin-top: 30px;
		}
		.table-finalizacao{
			.content{
				.sub-header{
					.qtd,
					.valor{
						display: none;
					}
				}
				.item{
					flex-direction: column;
					align-items: flex-start;
					.qtd,
					.valor{
						padding-left: 10px;
					}
				}
			}
		}
	}
}
//.pag-finalizar

.pag-contato.pag-body{
	.titulo-pag{
		font-size: 20px;
		line-height: 22px;
	}
	.bloco-captcha{
		text-align: center;
		margin-top: 25px;

		iframe{
			max-width: 100%;
		}
	}
	.btn-padrao{
		margin: 30px auto 0 auto;
	}
	.box-contatos{
		padding: 30px 15px;
		background-color: #eee;

		.subtitulo-pag{
			font-family: $mts-black;
			color: $vermelho;
			font-size: 20px;
			line-height: 22px;
			margin-bottom: 30px;
		}

		.item-contato + .item-contato{
			margin-top: 15px;
		}
		.item-contato{
			display: flex;
			color: #555;
			font-size: 15px;
			line-height: 17px;

			.icone{
				min-width: 30px;
				font-size: 18px;
				color: #000;
			}
		}
	}

	@include media-breakpoint-down(md) {
		.col-lg-4{
			order: 1;
			margin-bottom: 30px;
		}
		.col-lg-8{
			order: 2;
		}
	}
}// .pag-contato

.pag-meus-dados,
.pag-senha{

	.btn-padrao{
		margin-top: 20px;
		margin-left: auto;
	}

	@include media-breakpoint-up(sm) {
		.col-sm-6:nth-child(odd) {
			padding-right: 5px;
		}
		.col-sm-6:nth-child(even) {
			padding-left: 5px;
		}
	}
	@include media-breakpoint-down(sm) {
		.btn-padrao{
			margin-right: auto;
		}
	}
}

.pag-enderecos{
	.box-endereco{
		border-bottom: 1px solid #ccc;
		padding-bottom: 15px;
		margin-bottom: 15px;

		.content{
			font-size: 14px;
			line-height: 1.4;

			.subtitulo-pag{
				font-size: 14px;
				margin-bottom: 5px;
			}
		}
	}
	.botoes{
		display: flex;
		margin-top: 20px;
	}
	@include media-breakpoint-up(sm) {
		.box-endereco{
			display: flex;
			.content{
				flex-grow: 1;
				margin-right: 20px;
			}
			.btn-padrao{
				width: 120px;
				min-width: 120px;
				align-self: flex-end;
			}
		}
		.botoes{
			justify-content: flex-end;
			flex-wrap: wrap;

			.btn-padrao{
				margin: 0 0 0 20px;
			}
		}
		.btn-novo{
			margin-left: auto;
			margin-top: 30px;
		}
		.col-sm-6:nth-child(odd) {
			padding-right: 5px;
		}
		.col-sm-6:nth-child(even) {
			padding-left: 5px;
		}
	}
	@include media-breakpoint-only(xs) {
		.titulo-padrao{
			text-align: center;
		}
		.btn-padrao{
			margin-left: auto;
			margin-right: auto;
		}
		.box-endereco{
			text-align: center;
			.btn-padrao{
				margin-top: 10px;
			}
		}
		.botoes{
			flex-direction: column;

			a.btn-padrao{
				order: 2;
				margin-top: 15px;
			}
		}
	}
}// .pag-enderecos

.pag-pedidos{
	.item-pedido + .item-pedido{
		margin-top: 15px;
	}
	.item-pedido{
		background-color: #ededed;
		line-height: 1.2;

		div{
			padding: 10px;
		}

		.dados{
			background-color: #000;
			color: #fff;
			font-size: 14px;

			p:last-child{
				margin-top: 10px;
			}
		}

		.valor,
		.status{
			color: #1f1f1f;
			font-size: 14px;
		}
		.botao{
			text-align: center;
			a{
				background-color: $vermelho;
				color: #fff;
				display: block;
				padding: 15px 10px;
				font-size: 12px;
				font-family: $mts-bold;
				text-align: center;
				text-transform: uppercase;
			}
		}
	}
	// .item-pedido

	@include media-breakpoint-up(md) {
		.item-pedido{
			display: flex;

			div{
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
			.valor,
			.status{
				text-align: center;
			}
			.botao{
				a{
					max-width: 130px;
					margin: 0 auto;
				}
			}
		}
		// .item-pedido
	}
	@include media-breakpoint-down(sm) {
		.item-pedido{
			text-align: center;

			.valor,
			.status{
				border-bottom: 1px solid #ddd;
			}
			.botao{

			}
		}
	}
}// .pag-pedidos

.pag-pedidos-detalhes{

	.group-detalhes{
		margin-bottom: 30px;
		.numero-pedido{
			font-size: 26px;
			font-family: $mts-bold;
			color: #000;
			line-height: 1;
		}
		a{
			padding: 10px 20px;
			font-size: 12px;
			font-family: $mts-bold;
			background-color: #000;
			color: #fff;
			display: inline-block;
			text-transform: uppercase;
			transition: all 0.3s linear;

			&:hover{
				background-color: $vermelho;
			}
		}
	}

	.box-data-endereco{
		background-color: #ededed;
		color: #1f1f1f;
		font-size: 15px;
		line-height: 1.3;

		div{
			padding: 15px;
		}
	}
	// .box-data-endereco

	.barra-status{
		margin-top: 30px;

		.title{
			font-size: 18px;
			color: #868686;
			font-family: $mts-bold;
			line-height: 1;
			text-transform: uppercase;
		}
		.item-status{
			background-color: #ededed;
			padding: 5px 10px;
			font-family: $mts-bold;
			font-size: 12px;
			line-height: 1.1;
			color: #222;
			display: flex;
			align-items: center;

			.icone{
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 100% 100%;
				display: block;
				cursor: pointer;
				width: 20px;
				height: 20px;
				margin-right: 5px;

				&.realizado{
					background-image: url('../images/status1.png');
				}
				&.aprovado{
					background-image: url('../images/status2.png');
				}
				&.transporte{
					background-image: url('../images/status3.png');
				}
				&.entregue{
					background-image: url('../images/status4.png');
				}
			}
		}
	}
	// .barra-status

	.subtitulo-pedidos{
		font-size: 26px;
		margin-bottom: 30px;
		margin-top: 30px;
		color: #868686;
	}

	.box-pagamento{
		background-color: #ededed;
		color: #222;

		.col-style{
			padding: 10px;
			font-size: 14px;
			line-height: 1.4;

			p:first-child{
				font-family: $mts-bold;
			}
			p:last-child{
				font-size: 16px;
			}
			&:last-child{
				background-color: #000;
				color: #fff;
				font-family: $mts-bold;
			}
		}
	}
	// .box-pagamento

	@include media-breakpoint-up(lg) {
		.barra-status{
			display: flex;
			align-items: center;
			.title{
				margin-right: 15px;
			}
			.opcoes{
				display: flex;
				flex-grow: 1;
				justify-content: flex-end;

				.item-status{
					position: relative;

					i.icone{
						cursor: auto;
					}

					&.concluido{
						i.icone{
							opacity: 0;
						}
					}
					&.atual{
						background-color: $vermelho;
						color: #fff;
						i.icone{
							opacity: 0;
						}
						&:after{
							border-left: 15px solid $vermelho !important;
						}
					}
				}

				.item-status:nth-child(1),
				.item-status:nth-child(2),
				.item-status:nth-child(3){
					margin-right: 15px;
					&:after{
						content: '';
						position: absolute;
						left: 100%;
						top: 50%;
						transform: translateY(-50%);
						border-top: 15px solid transparent;
						border-bottom: 15px solid transparent;
						border-left: 15px solid #ededed;
						display: block;
					}
				}
				.item-status:nth-child(2),
				.item-status:nth-child(3),
				.item-status:nth-child(4){
					padding-left: 25px;

					&:before{
						content: '';
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
						border-top: 15px solid transparent;
						border-bottom: 15px solid transparent;
						border-left: 15px solid #fff;
						display: block;
					}
				}
			}
		}
		// .barra-status
		.box-pagamento{
			display: flex;
			.col-style{
				width: 20%;
			}
		}
	}
	@include media-breakpoint-up(md) {
		.group-detalhes{
			display: flex;
			align-items: center;

			.numero-pedido{
				flex-grow: 1;
			}
			a + a{
				margin-left: 10px;
			}
		}
		.box-data-endereco{
			display: flex;
			align-items: center;
			.data{
				min-width: 180px;
			}
		}
	}
	@include media-breakpoint-down(md) {
		.barra-status{
			.title{
				margin-bottom: 20px;
			}
			.opcoes{
				display: flex;
				justify-content: space-around;
				flex-wrap: wrap;

				.item-status{
					width: 100px;
					min-width: 100px;
					height: 100px;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					text-align: center;
					margin: 5px;

					i.icone{
						margin-bottom: 5px;
					}
					&.concluido{
						opacity: 0.2;
					}
					&.atual{
						background-color: $vermelho;
						color: #fff;
					}
				}
			}
		}
		.box-pagamento{
			text-align: center;
			.col-style{
				border-top: 1px solid #ddd;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.group-detalhes{
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			a{
				margin-top: 10px;
			}
		}
		.box-data-endereco{
			text-align: center;
			.data{
				border-bottom: 1px solid #ddd;
			}
		}
	}
}// .pag-pedidos-detalhes