.banners{
	width: 100%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;

	.carousel-indicators{
		margin-bottom: 0;
		bottom: 20px;

		li{
			width: 12px;
			height: 12px;
			border-radius: 50%;
			border: none;
			background-color: #fff;
			opacity: 1;
			box-shadow: 0 0 4px rgba(0,0,0,0.4);
			margin: 0 8px;

			&.active{
				background-color: $vermelho;
			}
		}
	}

	@include media-breakpoint-only(xl) {
		margin-bottom: 20px;

		.carousel{
			position: relative;
			min-width: 1920px;
			left: 50%;
			transform: translateX(-50%);

			.carousel-inner{
				z-index: 2;
				.carousel-item{
					position: relative;
				}
			}
		}
		.carousel-indicators{
			left: auto;
			right: 0;
			margin-right: calc(calc(100% - 1110px)/2);
		}
	}
	@include media-breakpoint-down(md) {
		.carousel-indicators{
			display: none;
		}
	}
}
// .banners

.home-produtos,
.produtos-destaque{
	.owl-carousel{
		.owl-nav{
			position: absolute;
			left: 50%;
			width: 100%;
			.owl-prev,
			.owl-next{
				position: absolute;
			}
		}		
	}

	@include media-breakpoint-only(xl) {
		.owl-carousel{
			.owl-nav{
				top: -68px;
				transform: translateX(-50%);

				.owl-prev,
				.owl-next{
					top: 0;
				}
				.owl-prev{
					left: 0;
				}
				.owl-next{
					right: 0;
				}
			}
		}
	}
	@include media-breakpoint-down(lg) {
		.owl-carousel{
			.owl-nav{
				top: 35%;
				left: 50%;
				transform: translate(-50%, -50%);

				.owl-prev,
				.owl-next{
					position: absolute;
					top: 0;
					transform: translateY(-50%);
					width: 44px;
					height: 44px;
					font-size: 20px;
				}
				.owl-prev{
					left: 0;
				}
				.owl-next{
					right: 0;
				}
			}
		}
	}
	@include media-breakpoint-only(xs) {
		.owl-carousel{
			.owl-nav{
				max-width: 300px;
			}
		}
	}
}

.home-produtos{
	@include media-breakpoint-only(xl) {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	@include media-breakpoint-down(lg) {
		padding-top: 20px;
		padding-bottom: 20px;
	}
}
// .home-produtos

.produtos-destaque{
	padding: 20px 0;
}
// .produtos-destaque

.banners-central{
	padding: 20px 0;

	@include media-breakpoint-down(md) {
		.col-lg-4 + .col-lg-4{
			margin-top: 15px;
		}
	}
}
// .banners-central

.produtos-novos,
.produtos-separados,
.produtos-confira{
	padding: 15px 0 30px 0;

	.owl-carousel{
		.owl-nav{
			position: absolute;
			left: 50%;
			width: 100%;
			top: 35%;
			transform: translate(-50%, -50%);

			.owl-prev,
			.owl-next{
				position: absolute;
				top: 0;
				transform: translateY(-50%);
				width: 44px;
				height: 44px;
				font-size: 20px;
			}
			.owl-prev{
				left: 0;
			}
			.owl-next{
				right: 0;
			}
		}
	}

	@include media-breakpoint-only(xl) {
		.owl-carousel{
			.owl-nav{
				.owl-prev{
					left: -22px;
				}
				.owl-next{
					right: -22px;
				}
			}
		}
	}
	@include media-breakpoint-only(xs) {
		.owl-carousel{
			.owl-nav{
				max-width: 300px;
			}
		}
	}
}
// .produtos-novos

.marcas{
	background-color: #f3f3f3;
	padding: 40px 0;
	margin-bottom: 20px;

	.owl-carousel{
		.owl-nav{
			position: absolute;
			left: 50%;
			width: 100%;
			top: 50%;
			transform: translate(-50%, -50%);

			.owl-prev,
			.owl-next{
				position: absolute;
				top: 0;
				transform: translateY(-50%);
				width: 44px;
				height: 44px;
				font-size: 20px;
				background-color: #fff;
			}
			.owl-prev{
				left: 0;
			}
			.owl-next{
				right: 0;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.owl-carousel{
			.owl-nav{
				.owl-prev{
					left: -22px;
				}
				.owl-next{
					right: -22px;
				}
			}
		}
	}
}
// .marcas