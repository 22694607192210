.rodape{
	background-color: $vermelho;
	color: #fff;

	ul{
		list-style-type: none;
		margin-bottom: 0;
		padding-left: 0;
	}

	.bloco-newsletter{
		padding-bottom: 30px;
		background-repeat: no-repeat;
		background-position: center bottom;
		background-image: url('../images/bg-newsletter.png');
		overflow: hidden;

		.titulo{
			font-family: $mts-black;
			text-transform: uppercase;
			line-height: 1;
			text-align: center;
			width: 540px;
			max-width: 100%;
			margin: 0 auto 30px auto;
			color: #fff;
		}

		.form-newsletter{
			width: 800px;
			max-width: 100%;
			margin: 0 auto;

			.form-control{
				height: 44px;
				background-color: #fff;
				border: none;
				border-radius: 0;
				font-size: 14px;
				color: #878787;
				font-family: $mts-medium;

				&:focus{
					box-shadow: none;
				}

				&::placeholder{
					color: #878787;
				}
			}
			button{
				background-color: #000;
				color: #fff;
				border: none;
				outline: none;
				font-size: 14px;
				font-family: $mts-semibold;
				padding: 5px 10px;
			}
		}

		@include media-breakpoint-only(xl) {
			padding-top: 60px;
		}
		@include media-breakpoint-only(lg) {
			padding-top: 50px;
		}
		@include media-breakpoint-up(lg) {
			.titulo{
				font-size: 30px;
			}
			.form-newsletter{
				position: relative;
				padding-bottom: 40px;

				&:before,
				&:after{
					content: '';
					position: absolute;
					width: 42px;
					height: 26px;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 100% 100%;
					background-image: url('../images/arrow-details.png');
				}
				&:before{
					top: -80px;
					left: -70px;
				}
				&:after{
					bottom: 0px;
					right: -60px;
					transform: scaleX(-1);
				}
			}
		}
		@include media-breakpoint-down(md) {
			padding-top: 30px;

			.titulo{
				font-size: 22px;
			}
		}
	}// .bloco-newsletter

	.bloco-informacoes{
		background-color: #C5231B;
		padding: 30px 0;

		.box{
			.circle{
				width: 48px;
				min-width: 48px;
				height: 48px;
				border-radius: 50%;
				background-color: $vermelho;
				display: flex;
				justify-content: center;
				align-items: center;
				padding-left: 30px;
			}
			.content{
				color: #fff;
				font-size: 12px;
				line-height: 1.2;
				font-family: $mts-semibold;
				text-transform: uppercase;
				letter-spacing: 2px;

				.nome{
					font-family: $mts-extrabold;
					font-size: 17px;
				}
				.texto{
					display: inline-flex;
					align-items: center;

					svg{
						font-size: 16px;
						margin-right: 5px;
					}
				}
			}
		}

		@include media-breakpoint-only(xl) {
			.box{
				display: flex;
				align-items: center;
				.circle{
					margin-right: 15px;
				}
			}
		}
		@include media-breakpoint-down(lg) {
			.box{
				text-align: center;

				.circle{
					margin: 0 auto 10px auto;
				}
			}
		}
		@include media-breakpoint-down(md) {
			.col-lg-4 + .col-lg-4{
				margin-top: 20px;
			}
		}
	}// .bloco-informacoes

	.bloco-rodape{
		padding: 40px 0;
		background-repeat: no-repeat;
		background-position: center bottom;
		background-image: url('../images/bg-newsletter.png');
		overflow: hidden;
		font-size: 12px;
		line-height: 14px;

		.subtitulo{
			font-size: 20px;
			line-height: 1;
			text-transform: uppercase;
			font-family: $mts-black;
			margin-bottom: 20px;
		}

		.texto{
			margin-bottom: 20px;
		}

		.redes-sociais{
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			li + li{
				margin-left: 10px;
			}
			a{
				width: 28px;
				height: 28px;
				background-color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $vermelho;
			}
		}
		.item + .item{
			margin-top: 10px;
		}
		.item{
			display: flex;
			align-items: center;

			a:hover{
				text-decoration: underline;
			}

			svg{
				font-size: 14px;
				margin-right: 5px;
			}
		}

		.row + .row{
			padding-top: 30px;
			margin-top: 30px;
			border-top: 1px dotted #fff;
		}

		.subtitulo-menus{
			font-size: 14px;
			line-height: 1;
			text-transform: uppercase;
			font-family: $mts-black;
			margin-bottom: 10px;
		}
		.menu{
			li + li{
				margin-top: 5px;
			}
			a:hover{
				text-decoration: underline;
			}
		}
		.group-imagens{
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			figure + figure{
				margin-left: 10px;
			}
		}

		@include media-breakpoint-up(sm) {
			.group-imagens{
				figure + figure{
					margin-left: 10px;
				}
			}
		}
		@include media-breakpoint-down(md) {
			text-align: center;
			font-size: 14px;
			line-height: 16px;

			.col-lg-6 + .col-lg-6{
				margin-top: 20px;
			}
			.item,
			.redes-sociais,
			.group-imagens{
				justify-content: center;
			}
		}
		@include media-breakpoint-only(xs) {
			.col-sm-6 + .col-sm-6{
				margin-top: 20px;
			}
			.group-imagens{
				figure + figure{
					margin: 10px;
				}
			}
		}

	}// .bloco-rodape

	.autorais{
		font-size: 11px;
		padding: 10px 0;

		a{
			display: inline-flex;
			align-items: center;

			svg{
				font-size: 40px;
				margin-left: 5px;
			}
		}

		@include media-breakpoint-up(lg) {
			.container{
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
		@include media-breakpoint-down(md) {
			text-align: center;

			a{
				margin-top: 5px;
			}
		}
	}// .autorais
}
// .rodape