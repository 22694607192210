.btn-pill{
	border-radius: 2rem;
}
.btn-padrao{
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #050505;
	color: #fff;
	font-size: 13px;
	text-align: center;
	font-family: $mts-semibold;
	text-transform: uppercase;
	transition: all 0.3s ease-out;
	width: 100%;
	max-width: 200px;
	height: 44px;
	border: none;

	&:hover{
		background-color: $vermelho;
		color: #fff;
	}
}
// .btn-padrao