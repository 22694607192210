.topo {
	.box-telefone .fa-phone{
		transform: scale(-1,1);
	}

	@include media-breakpoint-up(xl) {
		.main-navigation{
			display: grid;
			grid-template-columns: auto repeat(2, minmax(min-content, max-content));
			grid-template-rows: auto auto;
		}

		.logo{
			grid-column: 1/2;
			grid-row: span 2;
		}

		.tab-content{
			grid-column: 2/3;
			grid-row: 1/2;
			margin-left: 0 !important;

			.tab-pane{
				margin-left: -15px;
			}
		}

		.desktop__only{
			grid-row: 2/3;
			grid-column: 2/3;

			display: flex;
			margin-left: auto;
			align-items: center;
		}

		.desktop__carrinho{
			a{
				display: flex;
				align-items: center;
				border: 2px solid $vermelho;
				padding: 0.75rem;
				position: relative;
				white-space: nowrap;
				min-height: 48px;

				& [class^="fa"] {
					color: $vermelho;
					display: inline-block;
					margin-right: 0.5em;
				}
			}

			.value{
				display: inline-block;
				padding-left: 0.5em;
				border-left: 1px solid #ccc;
			}

			.qtde{
				font-size: 10px;
				color: #FFF;
				padding: 4px;
				position: absolute;
				left: 0;
				top: 0;
				transform: translate(-50%, -50%);
				perspective: 3px;
				min-width: 28px;
				text-align: center;
				display: block;
				line-height: 1;

				&:before{
					content:'';
					background: $danger;
					position: absolute;
					width: 100%;
					padding-bottom: 100%;
					border-radius: 50%;
					z-index: -1;
					left:0;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}

		.desktop__search{
			margin-right: 62px;
			min-width: 433px;

			.input-group{
				.form-control{
					border-color: $vermelho;
					height: 48px;
				}

				.btn{
					@include button-variant($vermelho, $vermelho);
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}

				.form-control{
					border-width: 2px;
				}
			}
		}

		.btn-toggle{
			display: none !important;
		}

		.fx-topo{
			font-size: 13px;
			color: #FFF;
			background: #000;

			.container{
				display: flex;
				justify-content: center;
			}

			.msg-frete{
				padding-top: 10px;
				padding-bottom: 10px;
				padding-right: 15px;
				margin-right: auto;

				& ~ *{
					padding-left: 15px;
				}
			}
		}

		.box-telefone{
			&,
			& > a,
			& > span {
				display: flex;

				.fas{
					margin-right:5px;
				}
			}
		}

		.box-telefone,
		.box-help,
		.box-chat{
			padding-top: 10px;
			padding-bottom: 10px;
			margin-left: 15px;
			display: flex;

			a{
				display: block;
				padding: 10px 15px;
				margin-top: -10px;
				margin-bottom: -10px;
				transition: all 0.3s linear;

				&:hover{
					color: #FFF;
					background: $vermelho;
				}

			}
		}

		.user__controls{
			margin-left: 40px;
		}

		.user__actions{
			display: flex;

			a{
				display: block;
				padding: 10px 5px;
				transition: all 0.3s linear;

				&:hover,
				&[aria-expanded="true"] {
					background: $vermelho;
					color: #FFF;
				}

				& ~ a{
					margin-left: 15px;
				}
			}
		}
	}

	@include media-breakpoint-down(lg) {
		.desktop__only{
			display: none;
		}

		.fx-topo .container{
			display: none;
		}
	}
}

.banner__topo{
	background-image: linear-gradient(to right, #D99148, #B522B5);
	color: #FFF;
	font-size: 14px;
	text-align: center;

	.banner__topo__content{
		padding: 1em 0;
	}
}
