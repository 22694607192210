.card-produtos{
	width: 100%;
	max-width: 255px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	color: #555;

	.box-img{
		border: 1px solid #ddd;
		padding: 20px 0;
		position: relative;
		overflow: hidden;
		margin-bottom: 10px;
		transition: all 0.3s ease-out;

		.botao{
			position: absolute;
			background-color: $vermelho;
			color: #fff;
			width: 100%;
			display: flex;
			bottom: 0px;
			left: 0;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			text-transform: uppercase;
			font-family: $mts-black;
			padding: 10px 5px;
			opacity: 1;
			z-index: 1;
			transition: all 0.3s ease-out;

			svg{
				font-size: 24px;
				margin-right: 5px;
			}
		}
	}
	.nome{
		font-size: 15px;
		line-height: 17px;
		text-transform: uppercase;
		font-family: $mts-semibold;
		margin-bottom: 10px;
		transition: all 0.3s ease-out;
	}
	.valor{
		font-size: 18px;
		line-height: 20px;
		font-family: $mts-black;
		margin-bottom: 5px;
	}
	.estrelas{
		font-size: 22px;

		i.fas + i.fas{
			margin-left: 5px;
		}
	}

	&:hover{
		.box-img{
			border-color: $vermelho;
		}
		.nome{
			color: $vermelho;
		}
	}

	@include media-breakpoint-only(xl) {
		.box-img{
			.botao{
				opacity: 0;
				bottom: -44px;
			}
		}
		&:hover{
			.box-img{
				.botao{
					opacity: 1;
					bottom: 0;
				}
			}
		}
	}

}// .card-produtos

.pag-produtos{
	.card-produtos{
		margin-bottom: 30px;
	}
	.nav-categorias{
		ul{
			list-style-type: none;
			margin-bottom: 0;
			padding-left: 0;
			a:hover{
				text-decoration: underline;
			}
			span{
				color: #777;
			}
		}
		.header{
			background-color: #000;
			color: #fff;
			font-family: $mts-black;
			text-transform: uppercase;
			font-size: 18px;
			line-height: 20px;
			padding: 10px 20px;
			text-align: center;
		}
		.content{
			background-color: #eee;
			font-size: 14px;
			color: #000;
			padding: 0 10px 30px 10px;

			.sub-titulo{
				padding-top: 30px;
				margin-bottom: 10px;
				font-size: 18px;
				font-family: $mts-black;
				color: $vermelho;
				line-height: 20px;
			}
			.botoes{
				margin-top: 5px;
				a{
					font-size: 14px;
					font-family: $mts-black;
					color: $vermelho;
					line-height: 1;
					text-transform: uppercase;

					&.ocultar{
						display: none;
					}
					&[aria-expanded="true"] {
						&.ocultar{
							display: inline-block;
						}
						&.mostrar{
							display: none;
						}
					}
				}
			}
			.filtros{
				.filtro-group + .filtro-group{
					margin-top: 5px;
				}
				.filtro-group{
					input{
						position: fixed;
						bottom: 100%;
						opacity: 0;
					}
					input:checked ~ label.item{
						color: #000;
						.fas{
							color: $vermelho;
						}
					}
					.item{
						cursor: pointer;
						color: #545454;
						transition: all 0.3s linear;
						margin-bottom: 0;

						.fas{
							display: inline-block;
							margin-right: 2px;
							font-size: 14px;
							color: #000;
							transition: all 0.3s linear;
						}
					}
				}
			}
		}
	}
	// .nav-categorias

	@include media-breakpoint-up(lg) {
		.nav-categorias{
			.header{
				pointer-events: none;
			}
			.content{
				display: block;
			}
		}
	}
	@include media-breakpoint-down(md) {
		.nav-categorias{
			margin-bottom: 30px;
			.header{
				cursor: pointer;
			}
		}
	}
}// .pag-produtos

.pag-produtos-detalhes{

	.album-produtos{
		margin-top: 10px;
		display: flex;
		align-items: center;
		overflow-x: auto;
		padding-bottom: 10px;

		.img{
			width: 50px;
			box-shadow: 1px 1px 8px rgba(0,0,0,0.4);

			&:hover{
				box-shadow: 0px 0px 0px rgba(0,0,0,0.4);
			}
		}
		.img + .img{
			margin-left: 10px;
		}
	}
	.box-valor{
		padding-top: 10px;
		border-top: 5px solid #ddd;
		text-align: center;

		.segura{
			padding: 20px;
			background-color: #eee;
			line-height: 1.2;
			font-size: 12px;

			.valores{
				color: #000;

				.total{
					span{
						font-size: 20px;
					}
				}
			}
			.frase{
				color: #00B200;
				font-family: $mts-bold;
			}
		}
	}
	.box-frete{
		padding: 20px 20px 5px 20px;
		background-color: #eee;
		margin: 10px 0;

		.segura{
			display: flex;
			align-items: center;

			.input{
				flex-grow: 1;

				.input-group{
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					background-color: #fff;
					border: 1px solid #ccc;
					padding: 0 10px;
					height: 44px;

					input{
						background-color: transparent;
						border: none;
						font-size: 12px;
						color: #aaa;
						flex-grow: 1;
						height: 44px;
						outline: none;

						&::placeholder{
							color: #aaa;
						}
					}

					button{
						background-color: transparent;
						border: none;
						font-size: 13px;
						color: #222;
						outline: none;
						font-family: $mts-bold;
					}
				}
			}
			.botao-question{
				padding-left: 10px;
				.btn{
					background-color: $vermelho;
					color: #fff;
					height: 26px;
					width: 26px;
					padding: 0;

					i{
						font-size: 12px;
					}
				}
			}
		}
		.texto{
			display: flex;
			align-items: center;
			color: #111;
			font-size: 12px;
			margin-top: 5px;

			i{
				margin-right: 5px;
			}
		}
}
	.title{
		font-family: $mts-bold;
		font-size: 18px;
		text-transform: uppercase;
		color: #000;
		line-height: 20px;
	}
	.logo-produto{
		display: inline-block;
		margin: 10px 0;
	}
	.botao-carrinho{
		background-color: #eee;
		padding: 20px;

		a{
			background-color: $vermelho;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 5px;
			border-radius: 4px;
			font-family: $mts-bold;
			height: 40px;

			svg{
				margin-right: 10px;
				font-size: 24px;
			}
		}
	}
	.texto-destaque{
		margin: 15px 0;
	}
	.compartilhar{
		font-size: 12px;
		line-height: 14px;
		color: #555;
		font-family: $mts-bold;
		margin-bottom: 10px;
		font-style: italic;
	}
	.redes-sociais{
		list-style-type: none;
		margin-bottom: 0;
		padding-left: 0;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		li + li{
			margin-left: 5px;
		}
		a{
			width: 32px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 3px;
			font-size: 18px;
			color: #fff;

			&.fa-facebook-f{
				background-color: #445D96;
			}
			&.fa-twitter{
				background-color: #61ABE9;
			}
			&.fa-whatsapp{
				background-color: #25cc63;
			}
			&.fa-envelope{
				background-color: #cf493b;
			}
		}
	}

	.descricao{
		border-top: 3px solid #ddd;
		padding-top: 20px;
		margin-top: 30px;

		.desc-editor{
			color: #000;
		}

		.nav-tabs{
			border: none;

			.nav-link{
				border: none;
				background-color: #333;
				color: #fff;
				border-radius: 0;
				padding: 5px 15px;
				font-size: 14px;
				min-width: 120px;
				text-align: center;
				transition: all 0.3s linear;

				&.active{
					background-color: #ececec;
					color: #111;
				}
			}
		}

		.tab-content{
			padding-top: 15px;
		}
	}

	@include media-breakpoint-up(sm) {
		.botao-carrinho{
			a{
				font-size: 15px;
				line-height: 17px;
			}
		}
	}
	@include media-breakpoint-down(md) {
		.col-lg-6 + .col-lg-6{
			margin-top: 30px;
		}
	}
	@include media-breakpoint-up(sm) {
		.box-valor{
			.segura{
				display: flex;
				align-items: center;
				.valores{
					padding-right: 10px;
					margin-right: 10px;
					border-right: 2px solid #fff;
					flex: 1;
				}
				.frase{
					flex: 1;
				}
			}
		}
	}
	@include media-breakpoint-only(xs) {
		.box-valor{
			.segura{
				.valores{
					margin-bottom: 10px;
				}
			}
		}
		.box-frete{
			padding: 10px 10px 5px 10px;
		}
		.botao-carrinho{
			padding: 10px;

			a{
				font-size: 14px;
				line-height: 16px;
			}
		}
	}
}
// .produtos-detalhes

.produtos__relacionados{
	margin-top: 40px;

	.produtos__title{
		margin-bottom: 40px;
		text-align: center;
		overflow: hidden;
		padding: 10px 0;
		font-family: "montserratblack";
		font-size: 24px;

		span{
			display: inline-block;
			position: relative;

			&:before,
			&:after{
				content: '';
				width: 100vw;
				border-top: toEm(4,24) solid currentColor;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}

			&:before{
				right: calc(100% + 15px);
			}

			&:after{
				left: calc(100% + 15px);
			}
		}
	}

	.owl-prev,
	.owl-next{
		position: absolute;
		top: 126px;
		transform: translate(calc(50% * var(--x, 1)), -50%);
	}

	.owl-prev{
		left: 0;
		--x: -1;
	}

	.owl-next{
		--x: 1;
		right: 0;
	}
}
