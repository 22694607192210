.table-listagem{

	&.lista-pedidos{
		.up-md{
			background-color: #222;
		}
		.table-body{
			.item-comprado{
				.down-sm{
					color: #000;
				}
				.valor{
					color: #1f1f1f !important;
					font-family: $mts-regular !important;
				}
				.col-style:last-child{
					p{
						font-family: $mts-bold;
						color: #1f1f1f;
					}
				}
			}
		}
	}
	// &.lista-pedidos

	&.lista-carrinho{
		.up-md{
			background-color: #000;
		}
		.table-body{
			background-color: #fff;
			
			@include media-breakpoint-up(md) {
				border-left: 1px solid #ddd;
				border-right: 1px solid #ddd;
				border-bottom: 1px solid #ddd;
			}

			.item-comprado{
				.down-sm{
					color: $vermelho;
				}
			}
		}
	}
	// &.lista-pedidos

	.up-md{
		color: #fff;
		font-size: 14px;
		font-family: $mts-bold;
		line-height: 16px;
	}
	.col-style{
		padding: 10px;
	}
	.col-style:nth-child(2),
	.col-style:nth-child(3),
	.col-style:nth-child(4){
		text-align: center;
	}
	.table-body{
		line-height: 1.3;
		color: #555;

		
		.item-comprado{
			.col-style:nth-child(1){
				font-size: 14px;

				.img{
					position: relative;

					.btn-remover{
						background-color: #b3585b;
						width: 24px;
						height: 24px;
						border-radius: 50%;
						color: #fff;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 13px;
						line-height: 1;
						position: absolute;
						z-index: 2;
						left: -10px;
						top: -10px;
					}
				}
			}
			.col-style:nth-child(2) {
				.box-qtd{
					display: flex;
					align-items: center;
					justify-content: center;

					.input-number{
						width: 30px;
						background-color: transparent;
						outline: none;
						border: none;
						text-align: center;
					}
					button{
						background-color: transparent;
						outline: none;
						border: none;
						font-size: 12px;
						padding: 12px;
						background-color: #000;
						color: #fff;
						transition: all 0.2s linear;
					}
				}
			}
			.col-style:nth-child(3) {
				.only-promo{
					display: none;
					font-size: 14px;
					span{
						text-decoration: line-through;
					}
				}
				.valor{
					font-size: 20px;
					color: $vermelho;
					font-family: $mts-bold;
				}
			}
			.col-style:nth-child(4) {
				font-size: 20px;
				// font-family: $mts-bold;
			}

			&.promocao{
				.col-style:nth-child(3) {
					.only-promo{
						display: inline-block;
					}
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		.down-sm{
			display: none;
		}
		.up-md{
			display: flex;
		}
		.col-style:nth-child(1){
			width: 40%;
		}
		.col-style:nth-child(2){
			width: 15%;
		}
		.col-style:nth-child(3){
			width: 25%;
		}
		.col-style:nth-child(4){
			width: 20%;
		}
		.table-body{
			background-color: #ededed;

			.item-comprado + .item-comprado{
				border-top: 3px solid #ddd;
			}
			.item-comprado{
				display: flex;
				align-items: center;
				padding: 10px 0;

				.col-style:nth-child(1){
					display: flex;
					align-items: center;
					padding-left: 20px;

					.img{
						margin-right: 10px;
					}
				}
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.up-md{
			display: none;
		}
		text-align: center;
		.table-body{
			line-height: 1.2;

			.item-comprado{
				background-color: #ededed;

				.down-sm{
					margin-bottom: 5px;
					font-size: 20px;
				}
				.col-style{
					border: 1px solid #333;
				}
				.col-style:nth-child(1){
					.img{
						width: 100%;
						max-width: 80px;
						margin: 10px auto;
					}
				}
			}
			.item-comprado + .item-comprado{
				margin-top: 10px;
			}
		}
	}

}
// .table-listagem