body{
	font-family: $mts-regular;
}
body,
html{
	width: 100%;
	min-height: 100vh;
}
#app{
	overflow: hidden;
}
p, h1, h2, h3, h4, h5, h6, figure{
	margin-bottom: 0;
}
textarea{
	resize: none;
}
a{
	color: inherit;

	&:focus,
	&:hover{
		color: inherit;
		text-decoration: none;
	}
}
img[style] {
	max-width: 100%;
	height: auto;
}
*[data-animate] {
	opacity: 0;
}
.animated[data-animate] {
	opacity: 1;
}
.bg-toggle{
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0,0,0,0.6);
	width: 100%;
	height: 100%;
	z-index: 999;
	display: none;
}
.titulo-1{
	text-align: center;
	position: relative;
	line-height: 1;
	font-size: 23px;
	z-index: 1;
	margin-bottom: 30px;

	&:before{
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		width: 100%;
		height: 4px;
		background-color: #000;
		z-index: -1;
	}

	span{
		display: inline-block;
		font-size: 23px;
		line-height: 25px;
		color: #000;
		font-family: $mts-black;
		background-color: #fff;
		padding: 0 10px;
	}
}
.desc{
	font-size: 14px;
	color: #555;
	line-height: 16px;

	img{
		max-width: 100%;
		height: auto;
	}
	iframe{
		max-width: 100%;
	}
	a:hover{
		text-decoration: underline;
	}

	&.editor{
		p + p{
			margin-top: 1em;
		}
	}
}

.group-categorias{
	text-align: center;

	ul{
		list-style-type: none;
		margin-bottom: 0;
		padding-left: 0;
		font-family: $mts-black;
		line-height: 20px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		li{
			a{
				display: inline-block;
				color: #000;
				font-size: 18px;
				line-height: 20px;
				margin: 5px 10px;
				padding: 5px;
			}
		}
	}

	@include media-breakpoint-only(xl) {
		padding: 0 34px;
		border-bottom: 2px solid #eee;
		margin-bottom: 30px;

		ul{
			li{
				a{
					position: relative;

					&:before{
						content: '';
						position: absolute;
						bottom: -7px;
						left: 0;
						width: 100%;
						height: 2px;
						background-color: transparent;
					}
				}
				&.active a{
					&:before{
						background-color: $vermelho;
					}
				}
			}
		}
	}
	@include media-breakpoint-down(lg) {
		margin-bottom: 15px;

		ul{
			li{
				&.active a{
					color: $vermelho;
				}
			}
		}
	}
}// .group-categorias

.estrelas{
	i.fas{
		color: #ddd;

		&.full{
			color: #F4CC25;
		}
		&.half{
			position: relative;

			&:after{
				content: "\f089";
				font-family: 'Font Awesome 5 Free';
			    font-weight: 900;
			    color: #F4CC25;
			    position: absolute;
			    top: 0;
			    left: 0;
			}
		}
	}
}
.owl-carousel{
	.owl-nav{
		.owl-prev,
		.owl-next{
			width: 30px;
			height: 30px;
			border-radius: 50%;
			background-color: #efefef;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #aaa;
			line-height: 1;
			font-size: 14px;
			transition: all 0.3s linear;

			&:hover{
				background-color: $vermelho;
				color: #fff;
			}
		}
	}
}

.card-marcas{
	width: 100%;
	max-width: 200px;
	margin: 0 auto;
}