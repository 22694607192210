.form-estilos{

	label{
		font-size: 14px;
		line-height: 1;
		margin-bottom: 5px;
		color: #555;
	}
	input.form-control,
	select.form-control{
		height: 52px;
	}
	select.form-control{
		-webkit-appearance: none;  /* Remove estilo padrão do Chrome */
		-moz-appearance: none; /* Remove estilo padrão do FireFox */
		appearance: none; /* Remove estilo padrão do FireFox*/
		background: url('../images/select.png') no-repeat #eee;  /* Imagem de fundo (Seta) */
		background-position: 98% center;  /*Posição da imagem do background*/
	}
	.form-control{
		border: 1px solid transparent;
		border-radius: 0;
		font-size: 14px;
		color: #555;
		background-color: #eee;

		&:focus{
			box-shadow: none;
			border-color: $vermelho;
		}
		&::placeholder{
			color: #555;
		}
	}
	.btn-padrao{
		border: none;
		outline: none;
	}
	.form-group{
		margin-bottom: 10px;
	}
	.form-group.custom{
		font-size: 14px;
		color: #555;

		.group-opcoes{
			display: flex;
			align-items: center;
			margin-top: 15px;
		}
		.custom-input{
			font-style: italic;
			input:checked ~ label{
				&:before{
					background-color: $vermelho;
					border-color: $vermelho;
				}
			}
			label{
				margin-bottom: 0;
				position: relative;
				display: inline-flex;
				align-items: center;
				cursor: pointer;

				&:before{
					content: '';
					position: relative;
					display: block;
					width: 18px;
					height: 18px;
					border-radius: 50%;
					left: 0;
					top: 0;
					margin: auto 10px;
					background-color: #ddd;
					transition: all 0.2s linear;
				}
			}
		}
	}
	//custom input

	input[type="radio"],
	input[type="checkbox"]{
		position: fixed;
		bottom: 200%;
		opacity: 0;
	}
}
// .form-estilos