// Margins
@include margin-variant(30);
@include margin-variant(40);
@include margin-variant(60);
@include margin-variant(70);

[data-owl]{

	&:not(.ready){
		display: none;
	}

	&.disabled{
		cursor: not-allowed;
		opacity: 0.6;
	}
}


.slick-slider:not(.slick-initialized) {
	opacity: 0;
}


.alertas{
	position: fixed;
	right: 15px;
	top: 90px;
	max-width: calc(100% - 30px);
	z-index: 999;

	.media-body{
		align-self: center;
	}

	.fa-2x {
		margin-right: 10px;
	}

	.alert{
		border-radius: 0;
		border-color: rgba(#000,.3);
		box-shadow:0 0 8px rgba(#000,.5);
		max-width: 300px;
		animation: rubberBand 0.6s linear;
	}

	.alert-success{
		background: materialColor('green', 'darken-2');
		color: #FFF;
	}

	.alert-warning{
		background: materialColor('orange', 'darken-2');
		color: #FFF;
	}

	.alert-danger{
		background: materialColor('red', 'darken-2');
		color: #FFF;
	}

	.alert-info{
		background: materialColor('blue', 'darken-2');
		color: #FFF;
	}

	.alert-dark{
		background: #000;
		color:#FFF;
	}

	.alert-light{
		background: #FFF;
		color:#000;
	}

	.alert-purple{
		background: materialColor('purple', 'darken-2');
		color:#fff;
	}

	.alert-teal{
		background: materialColor('teal', 'darken-2');
		color:#fff;
	}
}