.topo{
	box-shadow: 0 0 6px rgba(0,0,0,0.6);
	position: relative;

	&.logado{
		@include media-breakpoint-only(xl) {
			.bar-topo{
				.box-conta{
					.opcoes-login{
						display: none;
					}
					.opcoes-conta{
						display: block;
					}

					&:hover{
						.box-absolute{
							display: block;
						}
					}
				}
			}
		}
		@include media-breakpoint-down(lg) {
			.main-navigation{
				.tab-content{
					#mainConta{
						li{
							display: block;
						}
						li:first-child,
						li:nth-child(2) {
							display: none;
						}
					}
				}
			}
		}
	}// &.logado

	ul{
		list-style-type: none;
		margin-bottom: 0;
		padding-left: 0;
	}

	.main-navigation{
		font-family: $mts-bold;
	}

	.logo{
		a{
			display: inline-block;
			overflow: hidden;
			position: relative;

			&:before{
				content:'';
				display: block;
				width: 50%;
				height: 300%;
				left: -50%;
				top: 50%;
				transform: translateY(-50%) rotate(15deg);
				background: linear-gradient(to right, transparent, #FFF, transparent);
				opacity: 0.5;
				position: absolute;
			}
		}

		&.active a{
			&:before{
				left: 100%;
				transition: left 0.3s linear;
			}
		}
	}

	.main-busca{
		position: fixed;
		top: -100px;
		left: 50%;
		transform: translateX(-50%);
		width: 400px;
		max-width: 100%;
		padding: 15px;
		z-index: 1000;
		transition: all 0.4s ease-out;

		&.active{
			top: 100px;
		}

		.input-group{
			.form-control{
				background-color: #eee;
				color: #555;
				font-size: 14px;
				height: 42px;
				border-radius: 0;
				border: none;

				&::placeholder{
					color: #555;
				}
				&:focus{
					box-shadow: none;
				}
			}
			button{
				background-color: $vermelho;
				color: #fff;
				border: none;
				outline: none;
				width: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 5px;
			}
		}
	}

	@include media-breakpoint-only(xl) {
		background-color: #fff;

		.mbl-controls{
			display: none;
		}

		.bar-topo{
			background-color: #000;
			color: #fff;
			font-size: 12px;
			font-family: $mts-bold;
			line-height: 14px;

			.container{
				display: flex;
				justify-content: space-between;
			}

			svg{
				margin-right: 5px;
			}

			.msg-frete{
				display: flex;
				align-items: center;
				flex-grow: 1;

				svg{
					font-size: 24px;
					color: $vermelho;
				}
			}
			.box-conta{
				display: flex;
				align-items: center;
				position: relative;

				svg{
					font-size: 20px;
					color: $vermelho;
				}
				.opcoes-login{
					display: flex;
					align-items: center;

					p{
						margin: 0 5px;
					}
					a:hover{
						text-decoration: underline;
					}
				}
				.opcoes-conta{
					display: none;
				}
				.box-absolute{
					display: none;
					position: absolute;
					top: calc(100% + 20px);
					left: 50%;
					transform: translateX(-50%);
					width: 200px;
					z-index: 10;
					background-color: #fff;
					filter: drop-shadow(0 0 10px rgba(0,0,0,0.4));

					&:before{
						content: '';
						position: absolute;
						bottom: 100%;
						left: 50%;
						transform: translateX(-50%);
						border-left: 25px solid transparent;
						border-right: 25px solid transparent;
						border-bottom: 15px solid #000;
					}

					&:after{
						content: '';
						position: absolute;
						bottom: 100%;
						left: 0;
						width: 100%;
						height: 20px;
						background-color: transparent;
					}

					.menu-conta{
						font-size: 14px;
						line-height: 16px;
						font-family: $mts-bold;
						text-transform: uppercase;
						color: #000;

						li + li{
							border-top: 1px solid #ccc;
						}

						li:first-child{
							background-color: #000;
							color: #fff;
							padding: 10px;

							span{
								display: block;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
							}
						}
						.active a{
							background-color: #ddd;
						}
						a{
							display: block;
							padding: 15px 10px;

							&:hover{
								background-color: #ddd;
							}
						}
						li:last-child{
							padding: 15px;

							a{
								background-color: $vermelho;
								color: #fff;
								padding: 10px;
								display: block;
							}
						}
					}
				}// .box-absolute
			}//.box-conta

			.box-carrinho{
				background-color: $vermelho;
				display: flex;
				align-items: center;
				padding: 6px 5px;
				margin-left: 10px;

				svg{
					font-size: 24px;
				}
			}
		}// .bar-topo

		.main-navigation{
			width: 100%;
			max-width: 1140px;
			margin: 0 auto;
			padding: 3px 15px 2px 15px;
			display: flex;
			align-items: center;

			.nav-tabs,
			#mainConta,
			.msg-frete{
				display: none;
			}
			.tab-content{
				flex-grow: 1;
				margin-left: 230px;

				ul{
					display: flex;
					justify-content: space-between;

					.active a{
						color: $vermelho;
					}

					a{
						font-size: 14px;
						line-height: 16px;
						color: #545454;
						display: block;
						padding: 15px;

						&:hover{
							color: $vermelho;
						}
					}
				}
			}
			.btn-toggle{
				width: 40px;
				height: 40px;
				background-color: #f3f3f3;
				color: #000;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				font-size: 18px;
				line-height: 1;
				margin-left: 50px;
			}

		}// .main-navigation
	}
	//DESKTOP
	@include media-breakpoint-down(lg) {
		.mbl-controls{
			text-align: center;

			.botoes-mbl{
				background-color: #000;
				display: flex;
				align-items: center;

				a{
					display: block;
					flex: 1;
					color: #fff;
					font-size: 24px;
					line-height: 1;
					padding: 8px 10px 13px 10px;
					cursor: pointer;
				}
			}
			.logo{
				padding: 2px 15px;

				img{
					width: 120px;
				}
			}
		}
		.bar-topo{
			display: none;
		}
		.main-navigation{
			position: fixed;
			top: 0;
			left: -250px;
			width: 250px;
			height: 100%;
			overflow-y: auto;
			z-index: 1000;
			background-color: #fff;
			transition: all 0.4s ease-out;
			border-right: 1px solid #111;

			&.active{
				left: 0;
			}
			.logo{
				text-align: center;
				margin-bottom: 10px;
			}
			.nav-tabs{
				display: flex;
				background-color: #ddd;
				border: none;
				color: #000;

				a{
					flex: 1;
					text-align: center;
					padding: 10px;
					display: block;

					&.active{
						background-color: $vermelho;
						color: #fff;
					}
				}
			}
			.tab-content{
				ul{
					a{
						display: block;
						padding: 10px;
						color: #000;

						&:hover{
							background-color: #ededed;
						}
					}
					.active a{
						background-color: #000;
						color: #fff;
					}
				}
				#mainConta{
					li{
						display: none;
					}
					li:first-child,
					li:nth-child(2) {
						display: block;
					}
				}
			}
			.msg-frete{
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				text-align: center;
				padding: 10px;
				color: #000;
				font-size: 14px;
				margin-top: 20px;

				svg{
					font-size: 30px;
					color: $vermelho;
				}
			}
			.btn-toggle{
				display: none;
			}
		}//.main-navigation
	}
	//DOWN-LG
}
// .topo

@import "_alteracoes.scss";
